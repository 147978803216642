import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Link } from "gatsby";

import Wrapper from "../components/wrapper";

import resume from "../assets/resume.pdf";
import logotype from "../../content/images/datananda-logotype.svg";
import { colors, mq } from "../assets/styles";

const NavListItem = styled("li")`
    a {
        font-family: agenda, sans-serif;
        color: white;

        &:hover,
        &.current-page {
            border-bottom: 1px solid white;
        }
    }
`;

const Header = () => (
    <header
        css={css`
            background: ${colors.neutrals[0]};
            padding: 0.5em;
        `}
    >
        <Wrapper>
            <nav
                css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;

                    ${mq[1]} {
                        flex-direction: row;
                    }
                `}
            >
                <Link to="/">
                    <img src={logotype} alt="datananda logotype" />
                </Link>
                <ul
                    css={css`
                        width: 100%;
                        justify-content: space-around;
                        margin-top: 0.5em;
                        display: flex;

                        li {
                            margin-top: 0;
                        }

                        ${mq[1]} {
                            width: auto;
                            margin-top: 0;

                            li {
                                margin-left: 3em;
                            }
                        }
                    `}
                >
                    <NavListItem>
                        <Link to="/" activeClassName="current-page">
                            portfolio
                        </Link>
                    </NavListItem>
                    <NavListItem>
                        <Link to="/about" activeClassName="current-page">
                            about
                        </Link>
                    </NavListItem>
                    <NavListItem>
                        <a
                            href={resume}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            resume
                        </a>
                    </NavListItem>
                    {/* <NavListItem>
                        <Link to="/contact">contact</Link>
                    </NavListItem> */}
                </ul>
            </nav>
        </Wrapper>
    </header>
);

export default Header;
