import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

import Wrapper from "../components/wrapper";
import { colors, mq } from "../assets/styles";

const FooterListItem = styled("li")`
    margin: 0.5em 0.25em;

    a {
        background-color: white;
        color: ${colors.blues[0]};
        border-radius: 100%;
        padding: 0.4em 0.3em;
        display: block;
    }
`;

const Footer = () => (
    <footer
        css={css`
            background: ${colors.neutrals[4]};
            margin-top: 0;
        `}
    >
        <Wrapper>
            <div
                css={css`
                    display: flex;
                    flex-direction: column-reverse;
                    justify-content: space-between;
                    align-items: center;
                    text-align: center;

                    p {
                        font-size: 0.75em;
                        margin-bottom: 0.5em;
                    }

                    ${mq[1]} {
                        flex-direction: row;
                        p {
                            font-size: 1.125em;
                            margin-bottom: 0;
                        }
                    }
                `}
            >
                <p>
                    Copyright &copy; 2019 - {new Date().getFullYear()} Madeleine
                    Filloux. All Rights Reserved.
                </p>
                <ul
                    css={css`
                        display: flex;
                        margin-top: 0;
                    `}
                >
                    <FooterListItem>
                        <a
                            href="mailto:madeleine@datananda.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={faEnvelope} fixedWidth />
                        </a>
                    </FooterListItem>
                    <FooterListItem>
                        <a
                            href="https://github.com/datananda"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={faGithub} fixedWidth />
                        </a>
                    </FooterListItem>
                    <FooterListItem>
                        <a
                            href="https://www.linkedin.com/in/datananda/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={faLinkedinIn} fixedWidth />
                        </a>
                    </FooterListItem>
                </ul>
            </div>
        </Wrapper>
    </footer>
);

export default Footer;
