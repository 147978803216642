export const colors = {
    blues: ["#0F3A5B", "#3E5271", "#656F88", "#8C92A6", "#AFB3C2"],
    neutrals: ["#454547", "#6D685F", "#8E7F66", "#B4A895", "#D5D6D8"],
    text: "#231F20",
};

export const border = `4px solid ${colors.neutrals[4]}`;

const breakpoints = [
    30 /* mobile landscape */,
    48 /* tablet */,
    62 /* small desktop */,
    75 /* large desktop */,
];

export const mq = breakpoints.map(
    breakpoint => `@media (min-width: ${breakpoint}em)`
);
