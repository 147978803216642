import React from "react";
import { css } from "@emotion/core";

const Wrapper = ({ children }) => (
    <div
        css={css`
            margin: auto;
            max-width: 90vw;
            width: 1000px;
        `}
    >
        {children}
    </div>
);

export default Wrapper;
