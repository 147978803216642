import React from "react";
import { css } from "@emotion/core";
import Image from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import { border, mq } from "../assets/styles";

const AboutPage = () => {
    const { image } = useStaticQuery(graphql`
        query {
            image: file(
                relativePath: { eq: "madeleine-filloux-profile-picture.JPG" }
            ) {
                sharp: childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <Layout>
            <h1>About</h1>
            <section
                css={css`
                    border: ${border};
                    padding: 1em;
                `}
            >
                <h2>What is datananda?</h2>
                <p>
                    “Datananda” is inspired by the Sanskrit word ananda (आनन्द),
                    meaning bliss or happiness.
                </p>
                <p>
                    It reflects my desire to transcend the often tedious and
                    repetitive nature of ordinary data interactions by creating
                    data experiences that are as engaging and enjoyable as they
                    are informative.
                </p>
            </section>
            <section>
                <h2>Who is Madeleine Filloux?</h2>
                <Image
                    fluid={image.sharp.fluid}
                    alt="Madeleine Filloux patting the heads of prairie dogs at the Prague Illustion Art Museum"
                    css={css`
                        ${mq[1]} {
                            width: 50%;
                            float: left;
                            margin-right: 1em;
                        }

                        ${mq[2]} {
                            width: 35%;
                        }
                    `}
                />
                <p
                    css={css`
                        ${mq[1]} {
                            margin-top: 0;
                        }
                    `}
                >
                    Madeleine Filloux started working with complex datasets
                    about{" "}
                    {new Date().getFullYear() -
                        new Date(2010, 5, 1).getFullYear()}{" "}
                    years ago at Brown University where she completed an
                    undergraduate honors thesis on identifying disease-causing
                    mutations in the human genome. (Incidentally, this is also
                    when she wrote some of her first “real” code and crashed the
                    lab computer by writing the output of her Perl program all
                    onto a single line.) Documenting the methodology for this
                    thesis work planted the seed for Madeleine&apos;s life goal:
                    to communicate information in a way that is both effective
                    and beautiful. This desire led her to a master&apos;s degree
                    in information science at the University of Michigan where
                    she expanded her interdisciplinary skill set with coursework
                    in human computer interaction, user experience, data
                    science, and graphic design.
                </p>
                <p>
                    Since then, Madeleine has worked with data across diverse
                    domains to deliver insights to a wide variety of
                    stakeholders. Her experience includes designing data driven
                    interfaces for industrial software applications to make them
                    intuitive for end users, developing interactive reporting
                    for an agricultural sales team to optimize their performance
                    and profitability, creating an aggregated view of an
                    enterprise&apos;s marketing data to deliver consolidated and
                    actionable insights, and building a custom business
                    intelligence web application for operational and financial
                    reporting at a sustainability financing company. She also
                    enjoys teaching and has done so as a 1:1 mentor through the
                    Data Visualization Society's mentorship program, as a boot
                    camp instructor at the University of Minnesota's Data
                    Visualization and Analytics Boot Camp, and as an adjunct
                    professor of Data Analytics & Visualization at the
                    University of St. Thomas.
                </p>
                <p>
                    Madeleine currently works as a Data Visualization Engineer
                    at Gantry, a startup helping users build AI and ML systems
                    that they trust. She still crashes her computer every now
                    and then when she pushes the boundaries of information
                    representation.
                </p>
            </section>
        </Layout>
    );
};

export default AboutPage;
