import React from "react";
import { Global, css } from "@emotion/core";
import Helmet from "react-helmet";

import useSiteMetadata from "../hooks/use-sitemetadata";

import Header from "../components/header";
import Footer from "../components/footer";
import Wrapper from "../components/wrapper";
import { colors } from "../assets/styles";

const Layout = ({ children }) => {
    const { title, description } = useSiteMetadata();

    return (
        <>
            <Global
                styles={css`
                    /* http://meyerweb.com/eric/tools/css/reset/ 
                    v2.0 | 20110126
                    License: none (public domain)
                    */

                    html,
                    body,
                    div,
                    span,
                    applet,
                    object,
                    iframe,
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6,
                    p,
                    blockquote,
                    pre,
                    a,
                    abbr,
                    acronym,
                    address,
                    big,
                    cite,
                    code,
                    del,
                    dfn,
                    em,
                    img,
                    ins,
                    kbd,
                    q,
                    s,
                    samp,
                    small,
                    strike,
                    strong,
                    sub,
                    sup,
                    tt,
                    var,
                    b,
                    u,
                    i,
                    center,
                    dl,
                    dt,
                    dd,
                    ol,
                    ul,
                    li,
                    fieldset,
                    form,
                    label,
                    legend,
                    table,
                    caption,
                    tbody,
                    tfoot,
                    thead,
                    tr,
                    th,
                    td,
                    article,
                    aside,
                    canvas,
                    details,
                    embed,
                    figure,
                    figcaption,
                    footer,
                    header,
                    hgroup,
                    menu,
                    nav,
                    output,
                    ruby,
                    section,
                    summary,
                    time,
                    mark,
                    audio,
                    video {
                        padding: 0;
                        border: 0;
                        font-size: 100%;
                        font: inherit;
                        vertical-align: baseline;
                    }
                    article,
                    aside,
                    details,
                    figcaption,
                    figure,
                    footer,
                    header,
                    hgroup,
                    menu,
                    nav,
                    section {
                        display: block;
                    }
                    body {
                        line-height: 1;
                    }
                    ol,
                    ul {
                        list-style: none;
                    }
                    blockquote,
                    q {
                        quotes: none;
                    }
                    blockquote:before,
                    blockquote:after,
                    q:before,
                    q:after {
                        content: "";
                        content: none;
                    }
                    table {
                        border-collapse: collapse;
                        border-spacing: 0;
                    }

                    /* my global styles */
                    * {
                        box-sizing: border-box;
                        margin: 0;
                    }

                    * + * {
                        margin-top: 1em;
                    }

                    html,
                    body {
                        margin: 0;

                        > div {
                            margin-top: 0;
                        }
                    }

                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6 {
                        font-family: agenda, sans-serif;
                        color: ${colors.blues[0]};

                        + * {
                            margin-top: 0.5rem;
                        }
                    }

                    h1 {
                        font-weight: 100;
                        font-size: 2.625em;
                    }

                    h2 {
                        font-weight: 100;
                        font-size: 1.875em;
                    }

                    a {
                        text-decoration: none;
                        color: ${colors.neutrals[2]};
                    }

                    p {
                        font-family: mrs-eaves, serif;
                        font-weight: 400;
                        font-size: 1.125em;
                        line-height: 1.25;
                        color: ${colors.text};
                    }

                    li {
                        color: ${colors.text};
                    }

                    #___gatsby > * {
                        display: flex;
                        flex-direction: column;
                        min-height: 100vh;
                    }

                    main {
                        flex-grow: 1;
                    }

                    img {
                        margin: 0;
                    }
                `}
            />
            <Helmet>
                <html lang="en" />
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <Header />
            <main
                css={css`
                    margin: 2rem auto;
                `}
            >
                <Wrapper>{children}</Wrapper>
            </main>
            <Footer />
        </>
    );
};

export default Layout;
